.App{
  --button-color:rgb(49, 92, 148);
  --bg-color:rgb(70, 65, 65);
}
.header{
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 50000;
  background: linear-gradient(to right,#140E38,#194089);
}
.navbar {
  /* padding: 15px; */
  width: 100%;
  background-color: transparent;
}
.color-white{
  color:white !important;
}
.navbar-nav a {
  color:white;
  text-shadow: 0 0 5px white;
}

.navbar-brand {
  font-family: "Poppins Medium";
  color: #302d3a;
  font-size: 20px;
}

.navbar-nav {
  font-family: "Poppins Normal";
  margin-left: 8%;
}

.navbar-nav > li {
  margin-left: 30px;
  padding: 2px;
  font-size: 16px;
}

.nav-button {
  text-align: center;
  margin: 0;
  font-size: 14px;
  /* background-color:var(--button-color); */
  background-image: linear-gradient(40deg,#BF68E6 20%,#9E48CD 51%,#BF68E6 90%);
  box-shadow: 0 0 12px 0 #9e48cd;
  border-radius: 4px;
  opacity: 1;
  font-family: "Poppins Normal";
  color: #ffffff;
}

.img-thumbnail {
  border: none;
}

.fixed {

  position: fixed;
  top: 0;
  left: 0;
  background: #194089;
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media (min-width: 700) {
  .d-fixed-top{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

}
@media (max-width: 425px) {

  .m-ml-2{
    margin-left: 15px;
  }
}
