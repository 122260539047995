@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

.backG{
  /* background: linear-gradient(to right,#140E38,#194089); */
  background: #f8f9fa;
}
/* .leftG{
  position: absolute;
  height: 100px;
  width: 100px;
  z-index: 999;

  background-image: url(./bitcoin.png);
  background-size: cover;
  left:-12.5%;
  top: 0%;
  opacity: 0.5;
  animation: logoAn 5s ease-in-out infinite;
}
.leftG:nth-of-type(even){
  background-image: url(./dogecoin.png);
  animation: logoAn 3s ease-in-out infinite;
}
.rightG{
  position: absolute;
  height: 100px;
  width: 100px;
  z-index: 999;

  background-image: url(./ethereum.png);
  background-size: cover;
  right:-12.5%;
  top: 0%;
  opacity: 0.5;
  animation: logoAn 5s ease-in-out infinite;
}
.rightG:nth-of-type(even){
  background-image: url(./litecoin.png);
  animation: logoAn 3s ease-in-out infinite;
} */
@keyframes logoAn{
  0%{
    transform: translateY(0%) rotateZ(0deg);
    
  }
  50%{
    transform: translateY(120vh) rotateZ(360deg);
  }
  100%{
    transform: translateY(0) rotateZ(0deg);
  }
}
.course-dashboard {
    position: relative;
    overflow: hidden;
    
    /* margin-top: 75px; */
    /* background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7wKu0THHDaWD2I30rGg9Pke_XoK_dj4xP-Vk3hZDi6he3oXqiwwe807udyMo7E1O6dmA&usqp=CAU'); */
    /*  */
    background: linear-gradient(to right,#140E38,#194089);
}
.container{
  position: relative;
}






.bg-white{
  background: white !important;
}

.course-dashboard img {
    position: absolute;
    top: 40px;
    right: -60px;
    transform: rotate(90deg);
}

.course-dashboard h1 {
  position: relative;
  
  
    padding:90px 0;
    color:white;
    animation: start-left 1s ease-in;
    /* font-family: 'Quicksand'; */
    /* text-shadow:0 0 5px white; */
    
}
.course-dashboard .circle{
  position: absolute;
  background-color: white;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transform: translate(-50%,-50%) scale(0);
  animation: scale 0.5s ease-out;
}

.view-all {
    color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 20px;
    background-color: #00008B;
    font-family: "Poppins Medium";
}
.user-details{
    position: relative;
    top: -50px;
    border-radius: 5px;
    padding: 25px 15px;
    background: white;
    /* background: rgb(255,167,167);
    background: linear-gradient(108deg, rgba(255,167,167,1) 0%, rgba(134,189,232,1) 100%);  */
    animation: start-right 1s ease-in-out;
    /* background: rgba(237,237,7,1); */
    /* background: rgb(16,240,239);
    background: radial-gradient(circle, rgba(16,240,239,1) 0%, rgba(237,237,7,1) 100%);  */
    /* background: rgb(255,255,255,0.5); */
    /* background: linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(130,148,205,0.5) 48%, rgba(203,204,208,0.5) 100%);  */
}
@keyframes start-left{
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(0%);
  }
}
@keyframes start-right{
  0%{
    opacity: 0;
    transform: translateY(100%);
  }
  100%{
    opacity: 1;
    transform: translateY(0%);
  }
}
.d-table{
    height:40px;
}
.d-table-cell{
    vertical-align: middle;
}
.course-button{
    text-align: center;
    margin: 0;
    font-size: 18px;
    background-image: linear-gradient(40deg,#BF68E6 20%,#9E48CD 51%,#BF68E6 90%);
    box-shadow: 0 0 12px 0 #9e48cd;
    border-radius: 4px;
    opacity: 1;
    font-family: "Poppins Medium";
    color: #fff !important;
}

@keyframes scale{
  to{
      transform: translate(-50%,-50%) scale(2);
      opacity: 0;
  }
}
.btnLabel{
  color: black;
}
/* .divide{
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(73,75,80,1) 48%, rgba(0,0,0,1) 100%); 
} */
.course-button:hover{
    color: #ffffff;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: rgb(7,13,77);
  background: radial-gradient(circle, rgba(7,13,77,1) 0%, rgba(8,5,52,1) 100%); 
    color:white;
    
    border-bottom: 2px solid rgb(15,10,80);
  }

.nav-tabs{
    border: none;
    padding-left:15px;
}
.nav-tabs .nav-link{
    border:none;
    color: black;
    margin-bottom:unset;
}
.nav a{
    color:#544E7A;
}
#offers-deals .offers {
    padding: 25px 0;
    margin-left: 12px;
    background-color: transparent;
}

.offers-body {
    position: relative;
    width: 335px;
    height: 290px;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.text {
    position: absolute;
    z-index: 1;
    width: 50%;
    margin-top: 30px;
    margin-left: 20px;
    font-family: 'Poppins Medium';
}

.text h6 {
    width: 120px;
    color: #5F5982;
    font-family: 'Poppins Medium';
}

.text h5 {
    color: #302D3A;
    font-size: 18px;
    font-family: 'Poppins Normal';
    font-weight: 600;
}

.discount-banner {
    color: white;
    position: absolute;
    z-index: 1;
    width: fit-content;
    position: absolute;
    left: -16px;
    bottom: 30px;
    text-align: center;
    padding: 15px 15px 10px;
    border-radius: 5px;
    background-color: #B3A092;
}

.discount-banner h6 {
    font-family: 'Poppins Normal';
}

.discount-banner h2 {
    font-size: 40px;
    line-height: 0.6;
    font-family: 'Poppins Medium';
}

.image {
    position: absolute;
    bottom: -1px;
    right: 0px;
    width: 175px;
    height: 274px;
}

.image img {
    display: block;
    width: 100%;
    height: 100%;
}

.pattern img {
    display: block;
    width: 100%;
    height: 100%;
}
/* owl css */
.owl-theme .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.offers-and-deals .owl-prev {
    position: absolute;
    outline: none;
    left: 5px;
    width: 40px;
    height: 40px;
}
.offers-and-deals .owl-prev span {
    width: 40px;
    height: 40px;
    content: url(./icons/wleft.png);
}
.offers-and-deals .owl-prev span:hover {
    width: 40px;
    height: 40px;
    content: url(./icons/right.png);
    transform: rotate(180deg);
}
button:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
}
.offers-and-deals .owl-next span {
    width: 40px;
    height: 40px;
    content: url(./icons/wleft.png);
    transform: rotate(180deg);
}
.offers-and-deals .owl-next span:hover {
    width: 40px;
    height: 40px;
    content: url(./icons/right.png);
    transform: rotate(0deg);
}
.offers-and-deals .owl-next {
    position: absolute;
    outline: none;
    right: 5px;
    width: 40px;
    height: 40px;
}
.owl-theme .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}
.offers-and-deals .owl-theme .owl-dots {
    margin-top: 20px;
}
.offers-and-deals .owl-theme .owl-dots .owl-dot {
    outline: none;
}

.m-show{
  display: none !important;
}

.m-hidden{
  display: flex !important;
}

.mobile-nav-bottom{
  border-top-left-radius: 35px !important;
  border-top-right-radius: 35px !important;
  z-index: 999;
}

ul.mobile-nav-bottom li.nav-item a.nav-link{
  padding: 5px;
}

li.nav-item a.nav-link.active{
  background: none !important;
  color: rgb(15,10,80) !important;
  fill:currentColor;
}
/* live sessions */
.notes-card.gray-card {
  border-bottom: 2px solid #707070;
}

.notes-card {
    width: 350px;
    min-height: 245px;
    padding: 50px 20px 20px;
    background-color: white;
    border-radius: 5px;
    border-top: 10px solid black;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
}
.gray-card {
    border: 2px solid #707070;
    border-bottom: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: transparent;
}
iframe{

  width: 100%;
  height: 200px;
  border-radius: 5px;

}


.announcements-box{
  grid-template-columns: repeat(3, 1fr)
}

@media (max-width: 425px) {
    .offers-and-deals .owl-theme .owl-dots .owl-dot span {
        width: 15px;
        margin: 0 5px;
    }
    .notes-card{
      width: auto;
    }
    .live-sessions video,.announcements-box video {
      margin: 0 auto;
      margin-top: 40px;
    }

    .live-sessions,.announcements-box {
      grid-template-columns: 1fr !important;
    }

    .offers-and-deals .owl-theme .owl-dots {
        margin-top: 0px;
    }

  .course-dashboard{
    margin-top: 0px;
    padding-top: 30px
  }

    .course-dashboard h1 {
        padding: 35px;
        font-size: 30px;
        text-align: center;
    }
    .user-details {
        position: relative;
        top: -20px;
        border-radius: 5px;
        padding: 12px;
        background-color: white;
    }

  .fm{
    padding: 0 0 3rem 0 !important;
    font-size: 25px !important
  }
    .m-show{
      display: block !important;
    }
    .m-hidden{
      display: none !important;
    }

    div.social-icons.row.text-muted.mt-center{
      margin-bottom: 1.5rem !important;
    }
    .mt-center{
      text-align: center !important;
    }
    p ul{
      padding-left: 20px
    }

    ul li.nav-item{
      text-align: center;
      min-width: 25% !important;
      max-width: 25% !important
    }

    p ul li{
      font-size: 14px
    }
}
