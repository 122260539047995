@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
html{
  scroll-behavior:smooth;
}
body{
  overflow-x: hidden;
}

@charset "utf-8";
/* CSS Document */

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins Medium";
  src: url("./fonts/Poppins-Medium.ttf");
  font-weight: 100;
}
.font-popins-b {
  font-family: "Poppins-Bold";
}
.font-popins-sb {
  font-family: "Poppins-SemiBold";
}

.font-popins-m {
  font-family: "Poppins Medium";
}

@font-face {
  font-family: "Poppins Normal";
  src: url("./fonts/Poppins-Regular.ttf");
  font-weight: 100;
}
.font-popins-r {
  font-family: "Poppins Normal";
}
.bg-color-grey{
  color:#F1F3F8;
}
.color-grey{
  color:#7A7A7A;
}
.btn:hover {
  color: #fff !important;
}
body {
  margin: 0;
  font-family: "Poppins-Regular"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white
}

.loader-box{
  position: relative;
  top: 45%;
}

.quiz-border{
  border: 1px dashed #00008B !important;
  background: #add8e6 !important;
  border-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*                                        custom  fixed                                             */

.main-text{
  color: #302D3A !important;
}

.duration a, .duration{
  /* color: #F68C20 !important; */
  color: white;
  text-decoration: none;
}

.success{
  background-color: #43C8B1 !important;
  color: white;
}

.error{
  /* background-image: linear-gradient(40deg, rgb(191, 104, 230) 20%, rgb(158, 72, 205) 51%, rgb(191, 104, 230) 90%);
  box-shadow: rgb(158, 72, 205) 0px 0px 12px 0px; */
  background-image: radial-gradient(circle, rgb(7, 13, 77) 0%, rgb(8, 5, 52) 100%);
  color:white;
}

.success-text{
  color:  #43C8B1 !important;
}

.s-component{
  max-height: 41px;
  min-height: 41px;
}

.bg-shade{
 background: #EFEFF6;
}

.col-2 .border , .col-12 .border{
  border: 1px solid #43C8B1 !important;
  border-radius: 5px
}

.p-medium{
  font-family: Poppins-Medium;
}

.p-bold{
  font-family: Poppins-Bold
}

.p-regular{
  font-family: Poppins-Regular
}

.p-extra-bold{
  font-family: Poppins-ExtraBold
}

.p-semi-bold{
  font-family: Poppins-SemiBold
}

.p-light{
  font-family: Poppins-Light
}

.p-medium{
  font-family: "Poppins-Medium";
}

.p-bold{
  font-family: "Poppins-Bold";
}

.p-regular{
  font-family: "Poppins-Regular";
}

.p-extra-bold{
  font-family: "Poppins-ExtraBold";
}

.p-semi-bold{
  font-family: "Poppins-SemiBold";
}

.p-light{
  font-family: "Poppins-Light";
}

.f-12{
  font-size: 12px;
}

.f-22{
  font-size: 22px ;
}

.f-16{
  font-size: 16px ;
}

.f-14{
  font-size: 14px ;
}

.f-24{
  font-size: 24px ;
}

.f-18{
  font-size: 18px ;
}

.f-20{
  font-size: 20px ;
}

.f-40{
  font-size: 40px;
}

/*                                       custom styles                                                                      */
.tab-content{
  width : 100% !important;
}

.tab-divider{

  height: 45px;
  background: white;
  /* background: linear-gradient(to right,#140E38,#194089); */
}

.card-body{
  padding: 0 !important;
  /* background: linear-gradient(to right,#140E38,#194089); */
  background: white;
}
.card-body .card-body{
  padding: 1rem !important;
}
.overview{
  position: absolute;
  border-radius: 8px;
  
  top: -200%;
  
  text-align: center;
  width: 100%;

  margin-bottom: 20px;
  opacity: 0;
  background: white;
  z-index: 999;
  color: black;
  padding: 20px;
  transform: translateY(200%);

}

.card{
  /* border-radius: 25px; */
  border: 4px solid rgba(0, 0, 0, 0.13);
  border-left: none !important;
  border-right: none !important;
  transform: translateX(200%);
  opacity: 0;
  transition: transform 1s ease , opacity 2s ease;
}
.card:nth-of-type(even){
  transform: translateX(-200%);
  opacity: 0;
}
.overview.isOn{
  position: relative;
  opacity: 1;
  transition: transform .5s ease-in-out , opacity 1s ease-in-out;
  transform: translateY(0%);
}
.card.show{
  opacity: 1;
  transform: translateX(0%);
}

.card .card{
  border-left: 1px solid rgba(0,0,0,.125) !important;
  border-right: 1px solid rgba(0,0,0,.125) !important;
}

div.w-25.side-bar{
  min-width: 360px !important;
}

.card .card .card-header{
  background: white;
  /* border: 1px solid rgb(0, 0, 0); */
  /* border-left: none;
  border-right: none; */
  box-shadow: 0 0 2px rgb(0, 0, 0,.125);
}

.card-header{
  /* background: linear-gradient(to right,#140E38,#51607a); */
  background: linear-gradient(to left,#140E38,#194089);
  /* border-left: 1px solid rgba(0,0,0,.125);
  border-right: 1px solid rgba(0,0,0,.125); */
  /* border-radius: .25rem; */
}

.card .card .card-body .row{
  padding: 5px 2px;
}

input.border-1:focus{
  outline :none !important;
  border-radius: 0 !important ;
}

.pb-2.pb-cust{
  padding-bottom: 10px !important
}

input, .bg-none{
border: none !important;
background: #F1F3F8;
border-radius: 0

}
.accordion{
  border-radius: 4px;
  color:#302D3A;
}
.queIndicator:focus{
  /* background-color: #F68C20 !important; */
  background-color: #140E38 !important;
  color: white !important
}

.selected{
  /* background: #F68C20 !important; */
  background: #140E38 !important;
  color: white !important
}

.option-border{
  border: 1px solid #CAC6D4;
  border-radius: 8px;
}
span{
  color: white;
}

@media (min-width:1200px) {
  div.questions div.col-2.px-0.my-1{
    flex: 0 0 5% !important;
  }
}

@media (max-width:500px) {
  .quiz-border{
    font-size: 12px!important
  }

  .mb-100{
    width: 100%  !important;
  }
  .f-20{
    font-size:18px ;
  }

  .loader-box{
    top: 40%;
  }

}


/* kinetic loader */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    box-sizing: border-box;
}

.loader-container {
  z-index: 1111;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background: #FFFFFF;
  opacity: 1;
}

.kinetic{
  position: relative;
  width: 40px;
  height: 40px;
  background: url('./icons/loader-icon.png') no-repeat center;
  background-size: contain;
  border-radius: 50%;
}

.kinetic::before {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 2.5px solid #194089;
  border-right: 2.5px solid #194089;
  border-top: 2.5px solid #FFFFFF;
  border-bottom: 2.5px solid #FFFFFF;
  border-radius: 50%;
  animation: rotate-left 1.5s linear infinite;
}

.kinetic::after {
  content: '';
  position: absolute;
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 2.5px solid #FFFFFF;
  border-right: 2.5px solid #FFFFFF;
  border-top: 2.5px solid #194089;
  border-bottom: 2.5px solid #194089;
  border-radius: 50%;
  animation: rotate-right 1.5s linear infinite;
}

@keyframes rotate-right{
  0%{
      transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
      transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes rotate-left{
  0%{
      transform: translate(-50%, -50%) rotate(360deg);
  }
  100%{
      transform: translate(-50%, -50%) rotate(0deg);
  }
}