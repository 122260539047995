.cookies-container {
  z-index: 11111;
  position: fixed;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
}

.cookies-container-show {
  display: block;
}

.cookies-box {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 410px;
  padding: 40px 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cookies-box a {
  color: #00008B;
}

.cookies-box button.cookies-btn {
  width: 100%;
  color: white;
  margin-top: 5px;
  background-color: #00008B ;
}

.cookie-img {
  width: 90px;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: white;
  color: #00008B;
  font-size: 60px
}

@media (max-width: 425px){
  .cookies-box {
      width: 80%;
      font-size: 14px;
      padding: 15px 25px 25px 25px;
  }
}
